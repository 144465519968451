<template>
  <csv-file-uploader class="new-rotation-upload" :template-link="templateLink" @drop="handleDrop">
    <template #content>
      <div>{{ I18n.t('rotation_upload.new.form.title') }}</div>
      <div class="space-y-2 my-2">
        <p class="text-base">{{ I18n.t('rotation_upload.new.form.description_1') }}</p>
        <p class="text-base">{{ I18n.t('rotation_upload.new.form.description_2') }}</p>
        <p class="text-base">{{ I18n.t('rotation_upload.new.form.description_3') }}</p>
        <p class="text-base">{{ I18n.t('rotation_upload.new.form.description_4') }}</p>
      </div>
      <table class="table table-bordered text-sm">
        <thead>
        <tr>
          <th>{{ I18n.t('rotation_upload.new.form.field_name') }}</th>
          <th>{{ I18n.t('rotation_upload.new.form.field_required') }}</th>
          <th>{{ I18n.t('rotation_upload.new.form.field_notes') }}</th>
          <th>{{ I18n.t('rotation_upload.new.form.field_example') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>{{ I18n.t('rotation_upload.new.form.name') }}</td>
          <td>{{ I18n.t('rotation_upload.new.form.name_required') }}</td>
          <td>{{ I18n.t('rotation_upload.new.form.name_notes') }}</td>
          <td>{{ I18n.t('rotation_upload.new.form.name_example') }}</td>
        </tr>
        <tr>
          <td>{{ I18n.t('rotation_upload.new.form.description') }}</td>
          <td>{{ I18n.t('rotation_upload.new.form.description_required') }}</td>
          <td>{{ I18n.t('rotation_upload.new.form.description_notes') }}</td>
          <td>{{ I18n.t('rotation_upload.new.form.description_example') }}</td>
        </tr>
        <tr>
          <td>{{ I18n.t('rotation_upload.new.form.department') }}</td>
          <td>{{ I18n.t('rotation_upload.new.form.department_required') }}</td>
          <td>{{ I18n.t('rotation_upload.new.form.department_notes') }}</td>
          <td>{{ I18n.t('rotation_upload.new.form.department_example') }}</td>
        </tr>
        <tr>
          <td>{{ I18n.t('rotation_upload.new.form.location') }}</td>
          <td>{{ I18n.t('rotation_upload.new.form.location_required') }}</td>
          <td>{{ I18n.t('rotation_upload.new.form.location_notes') }}</td>
          <td>{{ I18n.t('rotation_upload.new.form.location_example') }}</td>
        </tr>
        <tr>
          <td>{{ I18n.t('rotation_upload.new.form.length') }}</td>
          <td>{{ I18n.t('rotation_upload.new.form.length_required') }}</td>
          <td>{{ I18n.t('rotation_upload.new.form.length_notes') }}</td>
          <td>{{ I18n.t('rotation_upload.new.form.length_example') }}</td>
        </tr>
        <tr>
          <td>{{ I18n.t('rotation_upload.new.form.length_unit') }}</td>
          <td>{{ I18n.t('rotation_upload.new.form.length_unit_required') }}</td>
          <td>{{ I18n.t('rotation_upload.new.form.length_unit_notes') }}</td>
          <td>{{ I18n.t('rotation_upload.new.form.length_unit_example') }}</td>
        </tr>
        <tr>
          <td>{{ I18n.t('rotation_upload.new.form.day') }}</td>
          <td>{{ I18n.t('rotation_upload.new.form.day_required') }}</td>
          <td>{{ I18n.t('rotation_upload.new.form.day_notes') }}</td>
          <td>{{ I18n.t('rotation_upload.new.form.day_example') }}</td>
        </tr>
        <tr>
          <td>{{ I18n.t('rotation_upload.new.form.type') }}</td>
          <td>{{ I18n.t('rotation_upload.new.form.type_required') }}</td>
          <td>{{ I18n.t('rotation_upload.new.form.type_notes') }}</td>
          <td>{{ I18n.t('rotation_upload.new.form.type_example') }}</td>
        </tr>
        <tr class="bg-gray-50">
          <td colspan="4" class="font-medium text-black">{{ I18n.t('rotation_upload.new.form.shift_fields') }}</td>
        </tr>
        <tr>
          <td>{{ I18n.t('rotation_upload.new.form.shift_template') }}</td>
          <td>{{ I18n.t('rotation_upload.new.form.shift_template_required') }}</td>
          <td>{{ I18n.t('rotation_upload.new.form.shift_template_notes') }}</td>
          <td>{{ I18n.t('rotation_upload.new.form.shift_template_example') }}</td>
        </tr>
        <tr>
          <td>{{ I18n.t('rotation_upload.new.form.shift_position') }}</td>
          <td>{{ I18n.t('rotation_upload.new.form.shift_position_required') }}</td>
          <td>{{ I18n.t('rotation_upload.new.form.shift_position_notes') }}</td>
          <td>{{ I18n.t('rotation_upload.new.form.shift_position_example') }}</td>
        </tr>
        <tr>
          <td>{{ I18n.t('rotation_upload.new.form.shift_job_site') }}</td>
          <td>{{ I18n.t('rotation_upload.new.form.shift_job_site_required') }}</td>
          <td>{{ I18n.t('rotation_upload.new.form.shift_job_site_notes') }}</td>
          <td>{{ I18n.t('rotation_upload.new.form.shift_job_site_example') }}</td>
        </tr>
        <tr class="bg-gray-50">
          <td colspan="4" class="font-medium text-black">{{ I18n.t('rotation_upload.new.form.time_off_fields') }}</td>
        </tr>
        <tr>
          <td>{{ I18n.t('rotation_upload.new.form.time_off_type') }}</td>
          <td>{{ I18n.t('rotation_upload.new.form.time_off_type_required') }}</td>
          <td>{{ I18n.t('rotation_upload.new.form.time_off_type_notes') }}</td>
          <td>{{ I18n.t('rotation_upload.new.form.time_off_type_example') }}</td>
        </tr>
        <tr>
          <td>{{ I18n.t('rotation_upload.new.form.time_off_admin_notes') }}</td>
          <td>{{ I18n.t('rotation_upload.new.form.time_off_admin_notes_required') }}</td>
          <td>{{ I18n.t('rotation_upload.new.form.time_off_admin_notes_notes') }}</td>
          <td>{{ I18n.t('rotation_upload.new.form.time_off_admin_notes_example') }}</td>
        </tr>
        <tr>
          <td>{{ I18n.t('rotation_upload.new.form.time_off_start_time') }}</td>
          <td>{{ I18n.t('rotation_upload.new.form.time_off_start_time_required') }}</td>
          <td data-testid="timeFormat">
            {{ I18n.t('rotation_upload.new.form.time_off_start_time_notes', { format: timeFormat }) }}
          </td>
          <td data-testid="timeExample">
            {{ I18n.t(`rotation_upload.new.form.time_off_start_time_example_${use12hr ? '12' : '24'}hr`) }}
          </td>
        </tr>
        <tr>
          <td>{{ I18n.t('rotation_upload.new.form.time_off_end_time') }}</td>
          <td>{{ I18n.t('rotation_upload.new.form.time_off_end_time_required') }}</td>
          <td>{{ I18n.t('rotation_upload.new.form.time_off_end_time_notes', { format: timeFormat }) }}</td>
          <td>{{ I18n.t(`rotation_upload.new.form.time_off_end_time_example_${use12hr ? '12' : '24'}hr`) }}</td>
        </tr>
        </tbody>
      </table>
    </template>
    <template #file-uploader>
      <csv-file-upload-form
        ref="fileUploader"
        :error-message.sync="errorMessage"
        :processing.sync="processing"
        @submit="handleSubmit">
        {{ I18n.t('rotation_upload.new.form.file_upload_modal') }}
      </csv-file-upload-form>
    </template>
  </csv-file-uploader>
</template>

<script>
  import CsvFileUploader from '../uploaders/csv_file_uploader'
  import CsvFileUploadForm from '../uploaders/csv_file_upload_form.vue'

  export default {
    name: 'NewRotationUpload',
    props: {
      templateLink: String,
      use12hr: Boolean,
      pusherKey: String,
      pusherChannelName: String,
      pusherErrorEvent: String,
      pusherProcessingEvent: String
    },
    components: {
      CsvFileUploader,
      CsvFileUploadForm
    },
    data() {
      return {
        errorMessage: '',
        processing: false
      }
    },
    computed: {
      timeFormat() {
        let format = 'HH:MM'
        if (this.use12hr) {
          format += 'AM/PM'
        }
        return format
      }
    },
    mounted() {
      const pusher = window.getPusherInstance(this.pusherKey)
      const pusherChannel = pusher.subscribe(this.pusherChannelName)
      this.subscribeToChannelEvents(pusherChannel)
    },
    methods: {
      subscribeToChannelEvents(channel) {
        channel.bind(this.pusherErrorEvent, (data) => {
          this.processing = false
          this.errorMessage = data.error
        })
        channel.bind(this.pusherProcessingEvent, (data) => {
          setTimeout(() => window.location.href = `/rotation_uploads/${data.rotation_upload_id}`, 1000)
        })
      },
      handleDrop(event) {
        const { files } = event.dataTransfer
        if (files.length > 1) {
          $('.error-status').html(I18n.t('user_imports.errors.multiple_files'))
        } else {
          this.$refs.fileUploader.handleFileSubmit(files[0])
        }
      },
      handleSubmit(file) {
        const data = new FormData()
        data.append('rotation_upload[file]', file, file.name)
        const vm = this

        $.ajax({
          url: '/rotation_uploads',
          method: 'POST',
          data,
          contentType: false,
          processData: false,
          error: function(response) {
            const message = response?.responseJSON?.errors || 'An error occurred. Please try again.'
            vm.processing = false
            vm.errorMessage = message
          },
          success: function(response) {
            window.location.href = `/rotation_uploads/${response.id}`
          }
        })
      }
    }
  }
</script>

<style scoped>
  .bg-gray-50 {
    background-color: #f9fafb;
  }

  .font-medium {
    font-weight: 500;
  }
</style>
